export default () => {
  const isLocked = import.meta.server ? ref(false) : useScrollLock(document.body)

  const lock = () => isLocked.value = true
  const unlock = () => isLocked.value = false

  return {
    isLocked,
    lock,
    unlock
  }
}
